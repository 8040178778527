import moment from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { CANONICAL_URL } from '../constants';
import { routes } from '../routes';
moment.extend(advancedFormat);

export { moment };

export const getDateTime = (dateTime) => {
  if (!dateTime) {
    throw new Error('No dateTime provided')
  }
  if (dateTime instanceof Date) {
    return dateTime;
  }
  // duck type for anything with a toDate method
  if (dateTime && dateTime.toDate) {
    return dateTime.toDate();
  }
  if (typeof dateTime == "string" && !Number.isNaN(Date.parse(dateTime))) {
    return new Date(dateTime);
  }
  console.error(`Invalid date ${dateTime}`)
  throw new Error('Invalid argument to getDateTime');
}


export const getUKDateTime = (dateTime) =>
  (dateTime &&
    new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
      dateTime
    )) ||
  null;


// speed dating bank, 10/02/23 => speed-dating-bank-2-feb-2023
export const getEventUrlFromTitleDate = (title: string, date: Date | string, options: {canonical: boolean} = {canonical: false}) => {
  const formattedTitle = title.toLowerCase().split(' ').join('-');
  const formattedDate = moment(date).format('D-MMM-YYYY');
  return `${options.canonical ? `${CANONICAL_URL}${routes.events}/` : ''}${formattedTitle}-${formattedDate}`
}


// speed-dating-bank-2-feb-2023 => speed dating bank, 10/02/23
export const getTitleAndDateForEvent = (eventTitleAndDate) => {

  let IndividualElementsArray = eventTitleAndDate.split('-')
  const date = IndividualElementsArray.slice(IndividualElementsArray.length - 3, IndividualElementsArray.length).join(' ')
  const title = IndividualElementsArray.slice(0, IndividualElementsArray.length - 3).join(' ')
  return { date, title }
}

